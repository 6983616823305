<template>
  <div>
    <!-- 头部 -->
    <!-- <Header></Header> -->
    <div class="BoxHeight bbox plr13 pb15">
      <div class="mt15 typeItem flex align-center mb10"
           @click="jump('/instrumentData',1)">
        <img src="../../assets/exam_1.png"
             alt=""
             width="60" />
        <span class="pl15 f15 text111">{{ $t('iconMenu.trainingMaterials') }}</span>
      </div>
       <div class="typeItem flex align-center mb10"
           @click="jump('/science',3)">
        <img src="../../assets/exam_3.png"
             alt=""
             width="60" />
        <span class="pl15 f15 text111">{{ $t('iconMenu.academicTest') }}</span>
      </div>
      <div class="typeItem flex align-center mb10"
           @click="jump('/instrumentData',2)">
        <img src="../../assets/exam_2.png"
             alt=""
             width="60" />
        <span class="pl15 f15 text111">{{ $t('navbar.trainingTest') }}</span>
      </div>
     
      <div class="typeItem flex align-center mb10"
           @click="jump('/testList',4)">
        <img src="../../assets/exam_4.png"
             alt=""
             width="60" />
        <span class="pl15 f15 text111">{{ $t('iconMenu.primaryTest') }}</span>
      </div>
      <div class="typeItem flex align-center mb10"
           @click="jump('/testList',6)">
        <img src="../../assets/exam_6.png"
             alt=""
             width="60" />
        <span class="pl15 f15 text111">{{ $t('iconMenu.juniorTest') }}</span>
      </div>
      <div class="typeItem flex align-center mb10"
           @click="jump('/testList',5)">
        <img src="../../assets/exam_5.png"
             alt=""
             width="60" />
        <span class="pl15 f15 text111">{{ $t('iconMenu.intermediateTest') }}</span>
      </div>

      <div class="typeItem flex align-center mb10"
           @click="jump('/testList',7)">
        <img src="../../assets/exam_7.png"
             alt=""
             width="60" />
        <span class="pl15 f15 text111">{{ $t('iconMenu.advancedTest') }}</span>
      </div>
    </div>
    <!-- 弹窗 -->
    <van-dialog v-model="show"
                title="考试说明"
                :show-cancel-button="false"
                :show-confirm-button="false"
                :closeOnClickOverlay="true">
      <div class="content bbox f13 text333 mt10">
        新要求 1.语言文字运用（1）词语辨析
        ①考查范围扩大。一改往年只考查成语（辨析或选用），变为考查实词（双音节、三音节）、短语、成语，而且都是常见、常用的词语。
        ②考查位置改变。全国卷Ⅱ在病句题中考查词语的正确使用。 （2）补写句子
        渗透逻辑推断能力。三套全国卷的第20题都采用了往年常考题型——补写句子，但不同的是，三道试题都选择了生物科普类材料。要拟写出正确答案，除了要考虑前后语意连贯、内容贴切外，还要深谙其中的科学原理，这类题型凸显了对考生逻辑推断能力的考查。
        （3）新闻压缩旧题型重新包装。三套全国卷的第21题都考查了对新闻报道的文字进行压缩，三道试题都选取真实新闻报道。试题要求提取关键信息，考查考生的信息处理能力。
        2.文言文阅读材料来源更靠前。选取了“二十四史”中成书最早的《史记》，且都是考生熟知的历史人物，体现了对教材知识的迁移。
        3.论述类文本阅读渗透课标“学习任务群”思想。全国卷Ⅰ第3题的C项，考查了文本内容与课外说法的相通性。
        4.实用类文本阅读选材范围扩大。全国卷Ⅰ选择了科普说明文文段。
        5.文学类文本阅读渗透整本书阅读要求。全国卷Ⅰ的第9题，从鲁迅整本书的角度，考查考生对文本的理解能力。
        6.写作应用文体全面呈现。全国卷Ⅰ和全国卷Ⅱ考查了演讲稿、书信、观后感，把应用文的写作作为考查的一个方面。
      </div>
      <div class="btns mt15 mb10">
        <div class="text666 f13 mb10 ml20">
          <el-checkbox v-model="checked"></el-checkbox>
          已阅读并同意考试说明
        </div>
        <div class="textc">
          <el-button type="primary"
                     @click="gotoExamination"
                     class="wp80 f16 Btn">开始测试</el-button>
        </div>
      </div>
    </van-dialog>
    <!-- 页脚 -->
    <!-- <Footer></Footer> -->
  </div>
</template>

<script>
import Header from "../../components/commonHead.vue";
import Footer from "../../components/footer.vue";
import { Dialog, Toast } from 'vant';
export default {
  // components: { Header, Footer },
  data () {
    return {
      show: false,
      checked: false,
    };
  },
  created(){
      console.log(this.$route.query);
      localStorage.setItem("member", this.$route.query.member);
      localStorage.setItem("memberCode", this.$route.query.memberCode);
  },
  methods: {
    openDialog () {
      this.show = true;
    },
    gotoExamination () {
      if (this.checked == false) {
        Toast("请阅读并同意考试说明");
      } else {
        this.show = false;
        this.$router.push(
          {
            path: '/examination'
          }
        )
      }
    },
    toExam (level, name) {
      var sendData = {};
      sendData.memberCode = localStorage.getItem("memberCode");
      sendData.levelCode = level;
      var that = this;
      this.$http.post("memberExam/queryMemberLevelExamList", sendData).then(function (res) {
        if (200 == res.data.code) {
          if (1 == res.data.data.count) {
            var examCode = res.data.data.data[0].examCode;
            var title = res.data.data.data[0].title;
            that.$router.push({
              path: "/testDetail",
              query: {
                examCode: examCode,
                title: title,
              }
            })
          } else if (1 < res.data.data.count) {
            that.$router.push({
              path: "/testCenter",
              query: {
                from: name,
                isTestRecord: "0",
              }
            })
          } else {
            that.$message("考试信息");
          }
        }
      })
    },
    jump (path, key) {
      var pushData = {};
      var query = {};
      pushData.path = path;
      //培训资料
      if (1 == key) {
        query.isModelData = "1";
        query.from = "培训资料";
        query.key = "培训资料";
        query.i = 0;
        pushData.query = query;
        this.$router.push(pushData);
      }
      //培训测试
      if (2 == key) {
        query.isTrain = "1";
        query.from = "培训测试";
        query.i = 0;
        pushData.query = query;
        this.$router.push(pushData);
      }
      //学术测试
      if (3 == key) {
        query.from = "学术测试";
        pushData.query = query;
        this.$router.push(pushData);
      }
      //初级考试
      if (4 == key) {
        this.toExam("1", "初级考试");
      }
      //中级考试
      if (5 == key) {
        this.toExam("3", "中级考试");
      }
      //初中级考试
      if (6 == key) {
        this.toExam("2", "初中级考试");
      }
      //中级考试
      if (7 == key) {
        this.toExam("4", "高级考试");
      }
    },
  },
};
</script>
<style scoped>
.typeItem {
  background-color: #f9f9f9;
  padding: 5px 10px;
}
.content {
  height: 50vh;
  padding: 0 15px;
  overflow-y: auto;
}
</style>